/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FiPlus, FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getResourcesByUserEmails from '../../../actions/getResourcesByUserEmails';
import getUserSuggestion from '../../../actions/getUserSuggestion';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../../../components/ui/dialog';
import Config from '../../../config';
import { cn, convertTo24Hour, formatDateString } from '../../../lib/utils';
import AddGuestModal from '../pages/back-office/components/add-guest-modal';
import GuestUserForm from '../pages/back-office/components/guest-user-form';
import Scheduler from '../pages/back-office/components/scheduler';
import AddProjectSpace from './add-project-space';
import FormBuilderForMeeting from './form-builder';
import MeetingScheduleSuccessModalUser from './meeting-schedule-success-modal-user';
import ProjectCard from './project-card';

function CreateMeetingButton({ className }) {
  const user = useGlobal('user')[0];
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');

  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [resources, setResources] = useState([]);

  const [slot, setSlot] = useState(15);

  const [selectedTime, setSelectedTime] = useState('');
  const [date, setDate] = useState(new Date());

  const [loading, setLoading] = useState(true);
  const [scheduleId, setScheduleId] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [bookings, setBookings] = useState('');
  const [meetingId, setMeetingId] = useState('');

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [requirePreMeetingForm, setRequirePreMeetingForm] = useState(true);

  const [isLoadingAddGuest, setIsLoadingAddGuest] = useState(false);
  const [showAddGuestModal, setShowAddGuestModal] = useState(false);
  const [isEditFormModalOpen, setIsEditFormModalOpen] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [guestName, setGuestName] = useState('');
  const [guestEmail, setGuestEmail] = useState('');
  // Guest
  const [guests, setGuests] = useState([]);

  const [selectedProject, setSelectedProject] = useState('');

  const [userSuggestion, setUserSuggestion] = useState([]);

  const navigate = useNavigate();

  const handleSchedule = () => {
    if (!selectedTime) {
      toast.error('Please select a time.', {
        position: 'top-center',
      });
      return;
    }

    const formData = new FormData();
    formData.append('date', formatDateString(date));
    formData.append('time', convertTo24Hour(selectedTime));
    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule`,
      data: formData,
    };

    setIsSubmitLoading(true);
    axios
      .request(config)
      .then((data) => {
        // console.log(data?.data?.message);
        const bookingId = data?.data?.data?._id;
        const meetingId = data?.data?.data?.meeting;
        setBookingId(bookingId);
        setMeetingId(meetingId);
        toast.success(data?.data?.message, {
          position: 'top-center',
        });

        setStep(1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getUserSuggestion(email);
        console.log(data.data);
        setUserSuggestion(data.data);
      } catch (err) {
        console.log(err);
        setUserSuggestion([]);
      }
    };
    if (email) {
      getData();
    } else {
      setUserSuggestion([]);
    }
  }, [email]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${Config.url || ''}/api/v1/user/book_schedule/get_bookings_with_schedule`)
      .then((data) => {
        const schedule = data?.data?.data?.schedule;
        const bookings = data?.data?.data?.bookings;
        const slot = Number(schedule?.timeSlot?.split(' ')[0]);
        setSlot(slot);

        setScheduleId(schedule?._id);
        setDaysOfWeek(schedule?.availableWeekdays);
        setBookings(bookings);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${Config.url || ''}/api/v1/user/book_schedule/get_forms`)
      .then((data) => {
        const forms = data?.data?.data;
        // console.log(forms);
        setForms(forms);
        setSelectedForm(forms[0]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const getResources = async () => {
    try {
      const guestEmails = guests.map((guest) => guest.email);

      const { data } = await getResourcesByUserEmails(guestEmails);
      // Sort the data to move the default item to the first index
      const sortedData = data.data.sort((a) => (a.default === true ? -1 : 1));
      setResources(sortedData);
      setSelectedProject(sortedData[0]?._id);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getResources();
  }, [guests]);

  const handleTriggerEditFormModal = (formData) => {
    setSelectedForm(formData);
    setIsOpen((prev) => !prev);
    setIsEditFormModalOpen((prev) => !prev);
  };

  const handleTriggerAddGuestModal = () => {
    setIsOpen((prev) => !prev);
    setShowAddGuestModal((prev) => !prev);
  };

  const handleSaveAndInvite = () => {
    if (!selectedForm) {
      addToast('Please select a Form', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('formId', selectedForm?._id);
    formData.append('bookingId', bookingId);
    formData.append('preMeetingFormRequired', requirePreMeetingForm);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule/save_n_invite`,
      data: formData,
    };

    setIsSubmitLoading(true);
    axios
      .request(config)
      .then((data) => {
        if (data?.data?.success) {
          setIsOpen(false);

          toast.success(data?.data?.message, {
            position: 'top-center',
          });

          // Reset Everything
          setStep(0);
          setSelectedTime('');
          setDaysOfWeek([]);
          setForms([]);
          setSelectedForm(null);
          setDate(new Date());
          setShowSuccessModal(true);
          setRefreshMeetings(!refreshMeetings);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleBookSchedule = async () => {
    // eslint-disable-next-line eqeqeq
    const isGuestAdded = await handleAddGuest();
    if (!isGuestAdded) {
      return;
    }
    setIsSubmitLoading(true);
    const formData = {
      scheduleId,
      bookingId,
      guests,
    };

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule/save_all_guests`,
      data: formData,
    };

    axios
      .request(config)
      .then((data) => {
        addToast(data?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
        });
        setStep(step + 1);
        setIsSubmitLoading(false);
        setRefreshMeetings(!refreshMeetings);
      })
      .catch((err) => {
        addToast(err?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        // setStep(step + 1);
        setIsSubmitLoading(false);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleAddGuest = (guestNameInput, guestEmailInput) => {
    if (!name && !guestNameInput) {
      addToast('Please enter your name', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return false;
    }
    if (!email && !guestEmailInput) {
      addToast('Please enter your email', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return false;
    }
    // eslint-disable-next-line eqeqeq
    if (user?.email == guestEmailInput || user?.email == email) {
      addToast('You cannot add your own mail', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return false;
    }
    let flag = false;

    // Check if the email is already exist on guest list.
    guests.forEach((guest) => {
      if (guest.email === email) {
        addToast('Email already exist on guest list', {
          appearance: 'warning',
          autoDismiss: true,
        });
        flag = true;
      }
    });

    if (flag) return false;

    const formData = new FormData();
    if (guestNameInput) {
      formData.append('name', guestNameInput);
    } else {
      formData.append('name', name);
    }
    if (guestEmailInput) {
      formData.append('email', guestEmailInput);
    } else {
      formData.append('email', email);
    }
    formData.append('scheduleId', scheduleId);
    formData.append('bookingId', bookingId);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule/send_guest_email`,
      data: formData,
    };
    setIsLoadingAddGuest(true);
    return axios
      .request(config)
      .then((data) => {
        addToast('Guest invited successfully.', {
          appearance: 'success',
          autoDismiss: true,
        });
        if (guestNameInput && guestEmailInput) {
          setGuestName('');
          setGuestEmail('');
          handleTriggerAddGuestModal();
        } else {
          setName('');
          setEmail('');
        }
        return true;
      })
      .catch((err) => {
        addToast(err?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        return false;
      })
      .finally(() => {
        setIsLoadingAddGuest(false);
      });
  };

  const handleAddResources = async (mID, rId) => {
    if (!mID || !rId) return;

    console.log('Meeting ID ', mID);
    console.log('Resource ID ', rId);
    setIsSubmitLoading(true);
    try {
      const res = await axios.post(`${Config.url || ''}/api/v1/user/add_meeting_to_resource`, {
        resourceId: rId,
        meetingId: mID,
      });
      addToast(res.data.message, { appearance: 'success' });
      setStep(3);
      setRefreshMeetings(!refreshMeetings);
    } catch (err) {
      if (err?.response?.data?.message) {
        addToast(err?.response?.data?.message, { appearance: 'error' });
      } else {
        addToast(err?.response?.data?.error || 'Something went wrong', { appearance: 'error' });
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger>
          <Button className={cn('flex items-center gap-2 hover:bg-[#193601] bg-[#173300] text-[#FFED74]', className)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="plus-circle">
                <path
                  id="Solid"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0007 0.833252C4.93804 0.833252 0.833984 4.93731 0.833984 9.99992C0.833984 15.0625 4.93804 19.1666 10.0007 19.1666C15.0633 19.1666 19.1673 15.0625 19.1673 9.99992C19.1673 4.93731 15.0633 0.833252 10.0007 0.833252ZM10.0007 5.83325C10.4609 5.83325 10.834 6.20635 10.834 6.66658V9.16658H13.334C13.7942 9.16658 14.1673 9.53968 14.1673 9.99992C14.1673 10.4602 13.7942 10.8333 13.334 10.8333H10.834V13.3333C10.834 13.7935 10.4609 14.1666 10.0007 14.1666C9.54041 14.1666 9.16732 13.7935 9.16732 13.3333V10.8333H6.66732C6.20708 10.8333 5.83398 10.4602 5.83398 9.99992C5.83398 9.53968 6.20708 9.16658 6.66732 9.16658H9.16732V6.66658C9.16732 6.20635 9.54041 5.83325 10.0007 5.83325Z"
                  fill="currentColor"
                />
              </g>
            </svg>
            <span className="text-base font-semibold leading-normal">Create meeting</span>
          </Button>
        </DialogTrigger>
        <DialogContent className="min-w-max" closeButtonClassName="top-2 right-2">
          {/* To Remove Warning */}
          <DialogTitle className="hidden" />
          <DialogDescription className="hidden" />
          {loading ? (
            <span className="visually-hidden">Loading...</span>
          ) : (
            <div className="space-y-6">
              {step === 0 &&
                (scheduleId ? (
                  <Scheduler
                    date={date}
                    setDate={setDate}
                    daysOfWeek={daysOfWeek}
                    selectedTimezone={selectedTimezone}
                    setSelectedTimezone={setSelectedTimezone}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    bookings={bookings}
                    slot={slot}
                  />
                ) : (
                  <div>
                    <div>
                      <h2 className="font-bold text-2xl">No Available Slots</h2>
                    </div>
                    <div className="py-4">
                      <p className="text-gray-500">
                        You currently have no available slots set up in your schedule. Please configure your
                        availability in the back office to start booking meetings.
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        className={cn('flex items-center gap-2 bg-black text-white', className)}
                        onClick={() => navigate('/back-office/scheduling')}
                      >
                        {' '}
                        <span>
                          <FiSettings />
                        </span>{' '}
                        <span>Go to setup</span>
                      </Button>

                      <Button
                        className={cn(
                          'flex items-center gap-2 bg-white text-black hover:bg-white hover:text-black border border-black',
                          className,
                        )}
                        onClick={() => setIsOpen(false)}
                      >
                        {' '}
                        <span>Cancel</span>
                      </Button>
                    </div>
                  </div>
                ))}
              {step === 0 && scheduleId && (
                <Button className="h-auto" disabled={!selectedTime} onClick={handleSchedule}>
                  {isSubmitLoading ? (
                    <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                  ) : (
                    'Continue'
                  )}
                </Button>
              )}

              {step === 1 && (
                <div>
                  <GuestUserForm
                    setStep={setStep}
                    step={step}
                    selectedTimezone={selectedTimezone}
                    selectedTime={selectedTime}
                    scheduleId={scheduleId}
                    setSelectedTimezone={setSelectedTimezone}
                    date={date}
                    bookingId={bookingId}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    isLoadingAddGuest={isLoadingAddGuest}
                    setIsLoadingAddGuest={setIsLoadingAddGuest}
                    handleAddGuest={handleAddGuest}
                    handleBookSchedule={handleBookSchedule}
                    name={name}
                    setName={setName}
                    email={email}
                    setEmail={setEmail}
                    guests={guests}
                    setGuests={setGuests}
                    isSubmitLoading={isSubmitLoading}
                    handleTriggerAddGuestModal={handleTriggerAddGuestModal}
                    userSuggestion={userSuggestion}
                  />
                </div>
              )}
              {step === 2 && (
                <div className="max-w-[700px] space-y-6">
                  <div className="grid grid-cols-3 gap-4 max-h-[420px] overflow-y-auto scroll-none">
                    {resources?.map((resource) => (
                      <div key={resource?._id} onClick={() => setSelectedProject(resource?._id)}>
                        <div className={selectedProject === resource?._id ? 'text-[#182230]' : 'text-[#d0d5dd]'}>
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.594727 10.1675C0.594727 4.92078 4.84802 0.66748 10.0947 0.66748C15.3414 0.66748 19.5947 4.92078 19.5947 10.1675C19.5947 15.4142 15.3414 19.6675 10.0947 19.6675C4.84802 19.6675 0.594727 15.4142 0.594727 10.1675Z"
                              fill="#F9FAFB"
                            />
                            <path
                              d="M0.594727 10.1675C0.594727 4.92078 4.84802 0.66748 10.0947 0.66748C15.3414 0.66748 19.5947 4.92078 19.5947 10.1675C19.5947 15.4142 15.3414 19.6675 10.0947 19.6675C4.84802 19.6675 0.594727 15.4142 0.594727 10.1675Z"
                              stroke="#D0D5DD"
                            />
                            <path
                              d="M6.09473 10.1675C6.09473 7.95834 7.88559 6.16748 10.0947 6.16748C12.3039 6.16748 14.0947 7.95834 14.0947 10.1675C14.0947 12.3766 12.3039 14.1675 10.0947 14.1675C7.88559 14.1675 6.09473 12.3766 6.09473 10.1675Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <ProjectCard
                          folderColor={resource?.default ? '#FFED74' : '#9ee870'}
                          title={resource?.default ? `${user.firstName}'s Default Space` : resource?.name}
                          description={resource?.description}
                          totalMeetings={resource?.meetings.length || false}
                          foldersData={['Call Time: 166m', 'Total Brief: 7']}
                        />
                      </div>
                    ))}
                    <AddProjectSpace
                      setProjectSpaceId={async (e) => {
                        await setSelectedProject(e);
                        handleAddResources(meetingId, e);
                        getResources();
                      }}
                      userEmails={guests.map((guest) => guest.email)}
                    />
                  </div>
                  <Button
                    onClick={() => handleAddResources(meetingId, selectedProject)}
                    className="px-[22px] py-4 bg-[#173300] hover:bg-[#173310] rounded-lg shadow border border-black text-[#ffed74] text-lg font-semibold font-inter leading-7 h-[60px]"
                  >
                    {isSubmitLoading ? (
                      <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                    ) : (
                      'Continue'
                    )}
                  </Button>
                </div>
              )}

              {step === 3 && (
                <>
                  <div>
                    {forms?.length === 0 && (
                      <div>
                        <div>
                          <h2 className="font-bold text-2xl">No Available Forms</h2>
                        </div>
                        <div className="py-4">
                          <p className="text-gray-500">
                            You currently have no available forms. Please add atleast one default forms in the back
                            office.
                          </p>
                        </div>
                        <div className="flex gap-2">
                          <Button
                            className={cn('flex items-center gap-2 bg-black text-white', className)}
                            onClick={() => navigate('/back-office/form-builder')}
                          >
                            {' '}
                            <span>
                              <FiPlus />
                            </span>{' '}
                            <span>Go to form setup</span>
                          </Button>

                          <Button
                            className={cn(
                              'flex items-center gap-2 bg-white text-black hover:bg-white hover:text-black border border-black',
                              className,
                            )}
                            onClick={() => setIsOpen(false)}
                          >
                            {' '}
                            <span>Cancel</span>
                          </Button>
                        </div>
                      </div>
                    )}
                    {forms && (
                      <div>
                        <div
                          onClick={() => setRequirePreMeetingForm(!requirePreMeetingForm)}
                          className="flex items-center gap-2 mb-[20px] border-t pt-2.5 mt-2"
                        >
                          {/* <Switch id="pre-meeting-form" checked={requirePreMeetingForm} onCheckedChange={setRequirePreMeetingForm} /> */}
                          <div className={requirePreMeetingForm ? 'text-[#182230]' : 'text-[#d0d5dd]'}>
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.594727 10.1675C0.594727 4.92078 4.84802 0.66748 10.0947 0.66748C15.3414 0.66748 19.5947 4.92078 19.5947 10.1675C19.5947 15.4142 15.3414 19.6675 10.0947 19.6675C4.84802 19.6675 0.594727 15.4142 0.594727 10.1675Z"
                                fill="#F9FAFB"
                              />
                              <path
                                d="M0.594727 10.1675C0.594727 4.92078 4.84802 0.66748 10.0947 0.66748C15.3414 0.66748 19.5947 4.92078 19.5947 10.1675C19.5947 15.4142 15.3414 19.6675 10.0947 19.6675C4.84802 19.6675 0.594727 15.4142 0.594727 10.1675Z"
                                stroke="#D0D5DD"
                              />
                              <path
                                d="M6.09473 10.1675C6.09473 7.95834 7.88559 6.16748 10.0947 6.16748C12.3039 6.16748 14.0947 7.95834 14.0947 10.1675C14.0947 12.3766 12.3039 14.1675 10.0947 14.1675C7.88559 14.1675 6.09473 12.3766 6.09473 10.1675Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <label
                            htmlFor="pre-meeting-form"
                            className="text-slate-800 text-sm font-medium leading-[14px] cursor-pointer"
                          >
                            Require Pre-Meeting Form
                          </label>
                        </div>
                        <div>
                          {forms.map((form, i) => (
                            <div key={i} className="flex items-center gap-5">
                              {/* <Checkbox
                                checked={form?._id === selectedForm?._id}
                                onCheckedChange={(e) => {
                                  if (form?._id === selectedForm?._id) {
                                    setSelectedForm(null);
                                  } else {
                                    setSelectedForm(form);
                                  }
                                }}
                                id="form"
                                className="border-gray-500 rounded"
                              /> */}
                              <div className="w-[500px] bg-[#eeeeee] rounded-lg border border-[#eaecf0] px-6 py-4 justify-between items-center gap-2.5 flex">
                                <label htmlFor="form" className="justify-start items-center gap-3 flex cursor-pointer">
                                  <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="file-search-02">
                                      <path
                                        id="Icon"
                                        d="M23.3332 18.3335H13.3332M16.6665 25.0002H13.3332M26.6665 11.6668H13.3332M33.3332 17.5002V11.3335C33.3332 8.53323 33.3332 7.1331 32.7882 6.06354C32.3088 5.12273 31.5439 4.35783 30.6031 3.87846C29.5336 3.3335 28.1334 3.3335 25.3332 3.3335H14.6665C11.8662 3.3335 10.4661 3.3335 9.39655 3.87846C8.45574 4.35783 7.69084 5.12273 7.21147 6.06354C6.6665 7.1331 6.6665 8.53323 6.6665 11.3335V28.6668C6.6665 31.4671 6.6665 32.8672 7.21147 33.9368C7.69084 34.8776 8.45574 35.6425 9.39655 36.1219C10.4661 36.6668 11.8662 36.6668 14.6665 36.6668H19.1665M36.6665 36.6668L34.1665 34.1668M35.8332 30.0002C35.8332 33.2218 33.2215 35.8335 29.9998 35.8335C26.7782 35.8335 24.1665 33.2218 24.1665 30.0002C24.1665 26.7785 26.7782 24.1668 29.9998 24.1668C33.2215 24.1668 35.8332 26.7785 35.8332 30.0002Z"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                  </svg>

                                  <p className="text-[#101828] text-sm font-medium leading-tight">
                                    {/* Form ({form._id}) */}
                                    Pre-Meeting Questionnaire
                                  </p>
                                </label>
                                <button
                                  onClick={() => handleTriggerEditFormModal(form)}
                                  className="px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] justify-center items-center gap-1 flex text-[#344054]"
                                >
                                  <span className="text-sm font-semibold leading-tight">Edit Form</span>
                                  <svg
                                    className="size-5"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="edit-05" clipPath="url(#clip0_11380_17018)">
                                      <path
                                        id="Fill"
                                        opacity="0.12"
                                        d="M6.6665 11.9378C6.6665 11.5301 6.6665 11.3263 6.71255 11.1345C6.75338 10.9644 6.82072 10.8018 6.9121 10.6527C7.01517 10.4845 7.1593 10.3404 7.44755 10.0521L15.4165 2.0832C16.1069 1.39284 17.2262 1.39284 17.9165 2.0832C18.6069 2.77355 18.6069 3.89284 17.9165 4.5832L9.94758 12.5521C9.65932 12.8404 9.5152 12.9845 9.347 13.0876C9.19788 13.179 9.03531 13.2463 8.86525 13.2871C8.67343 13.3332 8.46961 13.3332 8.06196 13.3332H6.6665V11.9378Z"
                                        fill="black"
                                      />
                                      <path
                                        id="Icon"
                                        d="M9.1665 3.3332H5.6665C4.26637 3.3332 3.56631 3.3332 3.03153 3.60568C2.56112 3.84536 2.17867 4.22782 1.93899 4.69822C1.6665 5.233 1.6665 5.93307 1.6665 7.3332V14.3332C1.6665 15.7333 1.6665 16.4334 1.93899 16.9682C2.17867 17.4386 2.56112 17.821 3.03153 18.0607C3.56631 18.3332 4.26637 18.3332 5.6665 18.3332H12.6665C14.0666 18.3332 14.7667 18.3332 15.3015 18.0607C15.7719 17.821 16.1543 17.4386 16.394 16.9682C16.6665 16.4334 16.6665 15.7333 16.6665 14.3332V10.8332M6.66648 13.3332H8.06193C8.46959 13.3332 8.67341 13.3332 8.86522 13.2871C9.03528 13.2463 9.19786 13.179 9.34698 13.0876C9.51517 12.9845 9.6593 12.8404 9.94755 12.5521L17.9165 4.5832C18.6069 3.89284 18.6069 2.77355 17.9165 2.0832C17.2261 1.39284 16.1069 1.39284 15.4165 2.0832L7.44753 10.0521C7.15928 10.3404 7.01515 10.4845 6.91208 10.6527C6.8207 10.8018 6.75336 10.9644 6.71253 11.1345C6.66648 11.3263 6.66648 11.5301 6.66648 11.9378V13.3332Z"
                                        stroke="currentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_11380_17018">
                                        <rect width="20" height="20" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <Button
                    onClick={handleSaveAndInvite}
                    className="px-[22px] py-4 bg-[#173300] hover:bg-[#173310] rounded-lg shadow border border-black text-[#ffed74] text-lg font-semibold font-inter leading-7 h-[60px]"
                  >
                    {isSubmitLoading ? (
                      <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                    ) : (
                      'Create Meeting'
                    )}
                  </Button>
                </>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={isEditFormModalOpen} onOpenChange={handleTriggerEditFormModal}>
        <DialogContent className="min-w-max" closeButtonClassName="text-xl">
          <DialogTitle>
            <p className="text-black text-base font-medium font-inter leading-normal">
              Form customization for meeting id #{meetingId}
            </p>
          </DialogTitle>
          <DialogDescription className="hidden" />
          <FormBuilderForMeeting
            formData={selectedForm}
            date={date}
            time={selectedTime}
            bookingId={bookingId}
            callBack={handleTriggerEditFormModal}
          />
        </DialogContent>
      </Dialog>
      <AddGuestModal
        showAddGuestModal={showAddGuestModal}
        handleAddGuest={handleAddGuest}
        isLoadingAddGuest={isLoadingAddGuest}
        name={guestName}
        setName={setGuestName}
        email={guestEmail}
        setEmail={setGuestEmail}
        handleTriggerAddGuestModal={handleTriggerAddGuestModal}
        setGuests={setGuests}
      />
      <MeetingScheduleSuccessModalUser
        open={showSuccessModal}
        setOpen={setShowSuccessModal}
        date={date?.toDateString()}
        time={selectedTime}
        timeZone={selectedTimezone}
        bookingId={bookingId}
        scheduleId={scheduleId}
        userName={user.username}
      />
    </>
  );
}

export default CreateMeetingButton;
