/* eslint-disable react/jsx-indent-props */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
// import { useMemo } from 'react';
import config from '../../../config';

function RoomCaptions({ room }) {
    // Use useMemo to prevent recalculating captions on every render
    const captionsToDisplay = () => {
        if (!room?.captions?.length) return [];

        const currentTime = new Date().getTime();

        // Only show captions whose showing time is still in the future
        return room.captions
            .filter((caption) => caption.showingTime > currentTime && caption.transcription?.segments?.length > 0)
            .slice(-3); // Limiting to the last 5 captions for a cleaner flow, adjust as needed
    };
    return (
        captionsToDisplay()?.length > 0 && (
            <div className="flex flex-col p-4 rounded-xl fixed bottom-40 mx-40 bg-opacity-75 bg-black text-white">
                {captionsToDisplay()?.map((caption, index) => (
                    <div key={caption.timestamp} className="flex items-start gap-2 py-1">
                        {/* Show user avatar and name only when the speaker changes */}
                        {(index === 0 || captionsToDisplay()?.[index - 1]?.user?._id !== caption.user?._id) && (
                            <img
                                src={caption.user?.picture?.location ? `${config.url}/${caption.user?.picture?.location}` : '/image/placeholder/avatar.png'}
                                className="w-10 h-10 rounded-full"
                                alt={`${caption.user?.firstName}'s avatar`}
                            />
                        )}
                        <div>
                            {/* Show speaker name when it changes */}
                            {(index === 0 || captionsToDisplay()?.[index - 1]?.user?._id !== caption.user?._id) && (
                                <span className="font-bold">{caption.user?.firstName}: </span>
                            )}
                            {/* Display the caption's text */}
                            <span>
                                {caption.transcription.segments.map((segment, segIndex) => (
                                    <span key={segIndex}>{segment.text} </span>
                                ))}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        )
    );
}

export default RoomCaptions;
