/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { useGoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { FiLogIn, FiUser } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import forgotPassword from '../../actions/forgotPassword';
import loginWithGoogle from '../../actions/googleLogin';
import initIO from '../../actions/initIO';
import login from '../../actions/login';
import register from '../../actions/register';
import resetPassword from '../../actions/resetPassword';
import setAuthToken from '../../actions/setAuthToken';
import ContinueAsGuestForm from '../../components/auth/ContinueAsGuestForm';
import { Checkbox } from '../../components/ui/checkbox';
import {
  InputOTP, InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '../../components/ui/input-otp';
import './Login.sass';
import Input from './components/Input';

function Login() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keep, setKeep] = useState(true);
  const [loginErrors, setLoginErrors] = useState({});

  const [emailCode, setEmailCode] = useState('');

  // const [registerUsername, setRegisterUsername] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerFirstName, setRegisterFirstName] = useState('');
  const [registerLastName, setRegisterLastName] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  // const [registerRepeatPassword, setRegisterRepeatPassword] = useState('');
  const [registerErrors, setRegisterErrors] = useState({});

  const [activePage, setActivePage] = useState('login');

  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const setToken = useGlobal('token')[1];
  const setUser = useGlobal('user')[1];
  const [entryPath, setEntryPath] = useGlobal('entryPath');

  const navigate = useNavigate();

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data: res } = await login(email, password);
      if (keep) localStorage.setItem('token', res.data.token);
      if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
      setLoginErrors({});
      setAuthToken(res.data.token);
      setUser(jwtDecode(res.data.token));
      setToken(res.data.token);
      dispatch(initIO(res.data.token));
      navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
      await setEntryPath(null);
    } catch (e) {
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setLoginErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onRegister = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data: res } = await register({
        username: registerEmail.split('@')[0],
        email: registerEmail,
        firstName: registerFirstName,
        lastName: registerLastName,
        fullname: `${registerFirstName} ${registerLastName}`,
        password: registerPassword,
        repeatPassword: registerPassword,
      });
      if (res.success) {
        toast.success('Registration Successful', {
          position: 'top-center',
        });
      }
      setRegisterErrors({});
      if (keep) localStorage.setItem('token', res.data.token);
      if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
      setAuthToken(res.data.token);
      setUser(jwtDecode(res.data.token));
      setToken(res.data.token);
      dispatch(initIO(res.data.token));
      navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
      await setEntryPath(null);
    } catch (e) {
      console.log(e);
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setRegisterErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onGuestContinue = async (res) => {
    try {
      setIsLoading(true);
      setRegisterErrors({});
      if (keep) localStorage.setItem('token', res.data.token);
      if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
      setAuthToken(res.data.token);
      setUser(jwtDecode(res.data.token));
      setToken(res.data.token);
      dispatch(initIO(res.data.token));
      navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
      await setEntryPath(null);
    } catch (e) {
      console.log(e);
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setRegisterErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { data: res } = await loginWithGoogle(tokenResponse?.access_token);
        if (keep) localStorage.setItem('token', res.data.token);
        if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
        setLoginErrors({});
        setAuthToken(res.data.token);
        setUser(jwtDecode(res.data.token));
        setToken(res.data.token);
        dispatch(initIO(res.data.token));
        navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
        await setEntryPath(null);
      } catch (e) {
        console.log(e);
        let errors = {};
        if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
        else errors = e.response.data;
        setLoginErrors(errors);
      } finally {
        setIsGoogleLoading(false);
      }
    },
    onError: (e) => {
      const errors = {
        message: (e?.error === 'access_denied' ? 'Access denied' : e?.error || e?.message) || 'Something went wrong',
      };
      setLoginErrors(errors);
      setIsGoogleLoading(false);
      console.log(e);
    },
    onNonOAuthError: (e) => {
      const errors = {
        message: e.message,
      };
      setLoginErrors(errors);
      setIsGoogleLoading(false);
      console.log(e);
    },
  });

  const onResetPassword = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data: res } = await forgotPassword(email);
      console.log(res);
      if (res.success) {
        toast.success('One time password has been sent to your email.', {
          position: 'top-center',
        });
        setActivePage('reset-password');
      }
    } catch (e) {
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setLoginErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };
  const onUpdatePassword = async (e) => {
    e.preventDefault();
    if (!email && !password && !emailCode) {
      toast.error('Please fill all the input fields.', {
        position: 'top-center',
      });
      return;
    }
    try {
      setIsLoading(true);
      const { data: res } = await resetPassword(email, emailCode, password);
      console.log(res);
      if (res.success) {
        toast.success('Password changed successfully.', {
          position: 'top-center',
        });
        setActivePage('login');
        setEmailCode('');
        setPassword('');
      }
    } catch (e) {
      const errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      setLoginErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (entryPath?.startsWith('/meeting')) {
      setActivePage('ask-for-meeting');
    }
  }, []);

  const loginInfo = Object.keys(loginErrors).map((key) => (
    <div className="uk-text-center" key={key}>
      <span className="uk-text-danger">{loginErrors[key]}</span>
    </div>
  ));

  const registerInfo = Object.keys(registerErrors).map((key) => (
    <div className="uk-text-center" key={key}>
      <span className="uk-text-danger">{registerErrors[key]}</span>
    </div>
  ));

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 h-screen">

      <div
        className="flex flex-col justify-between p-6 items-center bg-contain bg-top bg-no-repeat"
        style={{
          backgroundImage: 'url("/image/bg/login-page-pattern.png")',
        }}
      >
        <div />
        <div className="login-inner lg:w-[360px] uk-padding-small" data-uk-scrollspy="cls: uk-animation-fade">
          {/* <Logo /> */}
          {
            activePage === 'login' ? (
              <div className="text-center mb-6 w-full space-y-3">
                <h4 className="text-[#101828] text-3xl font-semibold">Welcome back</h4>
                <p className="text-[#475467]">Welcome back! Please enter your details.</p>
              </div>
            ) : activePage === 'continue-as-guest' ? (
              <div className="text-center mb-6 w-full space-y-3">
                <h4 className="text-[#101828] text-3xl font-semibold">Continue as guest</h4>
                <p className="text-[#475467]">Continue as guest. Please enter your details.</p>
              </div>
            ) : activePage === 'ask-for-meeting' ? (
              <div className="text-center mb-6 w-full space-y-3">
                <h4 className="text-[#101828] text-3xl font-semibold">Get started</h4>
                <p className="text-[#475467]">Select how you want to get started</p>
              </div>
            ) : (activePage === 'forgot-password' || activePage === 'reset-password') ? (
              <div className="mb-4 w-full space-y-3">
                <h4 className="text-[#101828] text-3xl font-semibold">Reset password</h4>
                <p className="text-[#475467]">
                  Enter your email to reset your password.
                </p>
              </div>
            )
              : (
                <div className="mb-4 w-full space-y-3">
                  <h4 className="text-[#101828] text-3xl font-semibold">Sign up</h4>
                  <p className="text-[#475467]">Start your 30-day free trial.</p>
                </div>
              )
          }

          <div className="w-full">

            {
              (activePage === 'login' || activePage === 'register') && entryPath?.startsWith('/meeting') && (
                <button
                  onClick={() => {
                    setActivePage('continue-as-guest');
                  }}
                  className="w-full px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex mb-4"
                >
                  <FiUser />
                  <span> Continue as Guest </span>

                </button>
              )
            }
            {
              activePage === 'login' && (
                <form onSubmit={onLogin}>
                  <fieldset className="space-y-6">
                    {loginInfo}
                    <Input
                      icon="user"
                      placeholder="Username (or email)"
                      label="Email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                      icon="lock"
                      placeholder="Password"
                      label="Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="my-4 flex items-center justify-between">
                      <label className="flex items-center gap-1">
                        <Checkbox
                          checked={keep}
                          onCheckedChange={(e) => setKeep(e)}
                          className="rounded overflow-hidden"
                        />
                        {/* <input
                          className="uk-checkbox"
                          type="checkbox"
                          onChange={(e) => setKeep(e.target.checked)}
                          checked={keep}
                        /> */}
                        {' '}
                        <span className="text-[#344054] text-sm font-medium leading-tight">Remember for 30 days</span>
                      </label>
                      <button type="button" onClick={() => setActivePage('forgot-password')} className="text-[#323333] text-sm font-semibold leading-tight">
                        Forgot password
                      </button>
                    </div>
                    <div className="">
                      <button type="submit" className="w-full px-4 py-2.5 bg-black rounded-lg shadow border border-black justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal">
                        {
                          isLoading ? (
                            <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                          ) : (
                            <span>
                              Sign in
                            </span>
                          )
                        }
                      </button>
                    </div>
                  </fieldset>
                </form>
              )
            }
            {
              activePage === 'forgot-password' && (
                <form onSubmit={onResetPassword}>
                  <fieldset className="space-y-6">
                    {loginInfo}
                    <Input
                      placeholder="Email"
                      label="Email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div>
                      <button type="submit" className="w-full px-4 py-2.5 bg-black rounded-lg shadow border border-black justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal">
                        {
                          isLoading ? (
                            <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                          ) : (
                            <span>
                              Reset Password
                            </span>
                          )
                        }
                      </button>
                    </div>
                  </fieldset>
                </form>
              )
            }
            {
              activePage === 'reset-password' && (
                <form onSubmit={onUpdatePassword}>
                  <fieldset className="space-y-3">
                    {loginInfo}
                    <Input
                      placeholder="Email"
                      label="Email"
                      type="text"
                      disabled
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div>
                      <p className="text-slate-700 text-sm font-medium leading-tight mb-2 block">OTP</p>
                      <InputOTP onChange={(e) => setEmailCode(e)} value={emailCode} maxLength={6} containerClassName="bg-white w-full">
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </div>
                    <Input
                      placeholder="New Password"
                      label="Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {/* <OTPInput></OTPInput> */}
                    <div>
                      <button type="submit" className="w-full px-4 py-2.5 bg-black rounded-lg shadow border border-black justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal">
                        {
                          isLoading ? (
                            <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                          ) : (
                            <span>
                              Reset Password
                            </span>
                          )
                        }
                      </button>
                    </div>
                  </fieldset>
                </form>
              )
            }

            {
              activePage === 'register' && (
                <form onSubmit={onRegister} className="space-y-6">
                  {registerInfo}
                  {/* <Input
                    icon="user"
                    placeholder="Username"
                    label="Username"
                    type="text"
                    onChange={(e) => setRegisterUsername(e.target.value)}
                  /> */}
                  <Input
                    icon="mail"
                    placeholder="Email"
                    label="Email"
                    type="email"
                    onChange={(e) => setRegisterEmail(e.target.value)}
                  />
                  <Input
                    icon="pencil"
                    placeholder="First Name"
                    label="First Name"
                    type="text"
                    onChange={(e) => setRegisterFirstName(e.target.value)}
                  />
                  <Input
                    icon="pencil"
                    placeholder="Last Name"
                    label="Last Name"
                    type="text"
                    onChange={(e) => setRegisterLastName(e.target.value)}
                  />
                  <Input
                    icon="lock"
                    placeholder="••••••••"
                    label="Password"
                    type="password"
                    onChange={(e) => setRegisterPassword(e.target.value)}
                  />
                  {/* <Input
                    icon="lock"
                    placeholder="••••••••"
                    label="Repeat Password"
                    type="password"
                    onChange={(e) => setRegisterRepeatPassword(e.target.value)}
                  /> */}
                  <div>
                    <button type="submit" className="w-full px-4 py-2.5 bg-black rounded-lg shadow border border-black justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal">
                      {
                        isLoading ? (
                          <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                        ) : (
                          <span>
                            Get Started
                          </span>
                        )
                      }
                    </button>
                  </div>
                </form>
              )
            }

            {
              activePage === 'ask-for-meeting' && (
                <div className="flex gap-3">
                  <button
                    onClick={() => {
                      setActivePage('continue-as-guest');
                    }}
                    className="w-full px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                  >

                    <FiUser />
                    <span> Guest </span>

                  </button>

                  <button
                    onClick={() => {
                      setActivePage('login');
                    }}
                    className="w-full px-4 py-2.5 bg-black text-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                  >

                    <FiLogIn />
                    <span> Login </span>

                  </button>
                </div>
              )
            }
            {
              activePage === 'continue-as-guest' && (
                <ContinueAsGuestForm onGuestContinue={onGuestContinue} entryPath={entryPath} />
              )
            }

            {/* <form className="toggle-password" hidden>
              <Input icon="mail" placeholder="Email" type="email" />
              <div className="uk-margin-bottom">
                <button type="submit" className="uk-button uk-button-primary uk-border-pill uk-width-1-1">
                  SEND CODE
                </button>
              </div>
            </form> */}
            {
              (activePage === 'login' || activePage === 'register') && (
                <div className="mt-6">
                  <button
                    onClick={() => {
                      setIsGoogleLoading(true);
                      googleLogin();
                    }}
                    className="w-full px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                  >
                    {
                      isGoogleLoading ? <span className="h-5 w-5 border-2 border-black rounded-full border-t-transparent animate-spin" /> : (
                        <>
                          <img src="/image/icons/google.svg" className="h-6 w-6" alt="" />
                          <span className="text-slate-700 text-base font-semibold leading-normal">
                            {
                              activePage === 'login' ? 'Sign in with Google' : 'Sign up with Google'
                            }
                          </span>
                        </>
                      )
                    }

                  </button>
                  {/* <GoogleLogin onSuccess={(res) => console.log(res)} onError={(err) => console.log(err)} /> */}
                </div>
              )
            }

            <div className="mt-6">
              {
                activePage === 'register' ? (
                  <p className="text-center">
                    Already registered?
                    {' '}
                    <span onClick={() => setActivePage('login')} className="cursor-pointer text-[#323333]">Login now</span>
                  </p>
                ) : (
                  <p className="text-center">
                    Don&apos;t have an account?
                    {' '}
                    <span onClick={() => setActivePage('register')} className="cursor-pointer text-[#323333]">Sign up</span>
                  </p>
                )
              }

              {/* <div className="uk-text-center" style={{ marginTop: 12 }}>
                <a className="uk-link-reset uk-text-small" href="#">
                  <Link to="/forgot-password">Forgot your password?</Link>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="w-full">
          © SayBriefly
          {' '}
          {new Date().getFullYear()}
        </div>
      </div>
      <div
        className="lg:h-full hidden lg:block bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: 'url("/image/bg/auth-bg.png")',
        }}
      />
    </div>
  );
}

export default Login;
