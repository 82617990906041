/* eslint-disable no-undef */
/* eslint-disable brace-style */
/* eslint-disable import/prefer-default-export */
import { clsx } from 'clsx';
import md5 from 'md5';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function generateTimeArray(startTimeStr, endTimeStr, timeFormat, slot) {
  // Parse start and end time strings
  const startTime = parseTimeString(startTimeStr);
  const endTime = parseTimeString(endTimeStr);

  // Initialize result array
  const timeArray = [];

  // Set initial time to the start time
  const currentTime = new Date(startTime);

  // Loop until the current time reaches the end time
  while (currentTime <= endTime) {
    // Add current time to the result array
    timeArray.push(formatTime(currentTime, timeFormat));

    // Increment current time by the slot duration
    currentTime.setMinutes(currentTime.getMinutes() + slot);
  }

  return timeArray;
}

export function formatTime(date, timeFormat) {
  // Get hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Format hours and minutes
  let hoursStr;
  let minutesStr;
  let period;

  if (timeFormat === '12h') {
    period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    hoursStr = hours.toString();
    minutesStr = minutes.toString().padStart(2, '0');
    return `${hoursStr}:${minutesStr} ${period}`;
  }
  hoursStr = hours.toString().padStart(2, '0');
  minutesStr = minutes.toString().padStart(2, '0');
  return `${hoursStr}:${minutesStr}`;
}

export function parseTimeString(timeStr) {
  if (!timeStr) {
    return;
  }

  // Split the time string into hours, minutes, and am/pm
  const parts = timeStr.split(/:|\s/); // Split by colon or space
  let hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const ampm = parts[2] ? parts[2].toLowerCase() : null; // Check if AM/PM exists

  // Adjust hours if 12h format and PM
  if (ampm === 'pm' && hours < 12) {
    hours += 12;
  }
  // Adjust hours if 12h format and AM and 12AM
  else if (ampm === 'am' && hours === 12) {
    hours = 0;
  }

  // Create a new Date object with today's date and the parsed time
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export function convertTo24Hour(timeStr) {
  if (!timeStr) {
    return;
  }

  // Trim the input string to remove any leading or trailing whitespace
  timeStr = timeStr?.trim();

  // Check if the time is already in 24-hour format
  const twentyFourHourFormat = /^([01]\d|2[0-3]):[0-5]\d$/;
  if (twentyFourHourFormat.test(timeStr)) {
    return timeStr;
  }

  // Extract hours, minutes, and period (AM/PM) using a regular expression
  const timeParts = timeStr?.match(/^(\d{1,2}):(\d{2})\s*([ap]m)$/i);

  // Check if the input string matched the expected format
  if (!timeParts) {
    console.error(`Invalid time format: ${timeStr}`);
    return null; // Return null or handle the error as needed
  }

  let hours = parseInt(timeParts[1], 10);
  const minutes = timeParts[2];
  const period = timeParts[3].toLowerCase();

  // Convert hours to 24-hour format
  if (period === 'pm' && hours !== 12) {
    hours += 12;
  } else if (period === 'am' && hours === 12) {
    hours = 0;
  }

  // Format hours to ensure it is two digits
  const formattedHours = hours.toString().padStart(2, '0');

  // Return the formatted time string
  return `${formattedHours}:${minutes}`;
}

export function convertTo12Hour(timeStr) {
  if (!timeStr) {
    return;
  }

  // Check if the time is already in 12-hour format
  const twelveHourFormat = /^(\d{1,2}):(\d{2})\s*(am|pm)$/i;
  if (twelveHourFormat.test(timeStr)) {
    return timeStr;
  }

  // Extract hours and minutes using a regular expression
  const timeParts = timeStr.match(/^(\d{2}):(\d{2})$/);

  // Check if the input string matched the expected format
  if (!timeParts) {
    console.error(`Invalid time format: ${timeStr}`);
    return null; // Return null or handle the error as needed
  }

  let hours = parseInt(timeParts[1], 10);
  const minutes = timeParts[2];
  let period = 'am';

  // Determine the period (AM/PM) and convert hours to 12-hour format
  if (hours >= 12) {
    period = 'pm';
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  // Format hours to ensure it is two digits if needed
  const formattedHours = hours.toString();

  // Return the formatted time string
  return `${formattedHours}:${minutes} ${period}`;
}

export function isTimeLater(timeString) {
  if (!timeString) {
    return false;
  }
  const time = convertTo24Hour(timeString);

  // Get the current time
  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();

  // Parse the time string
  const [inputHours, inputMinutes] = time.split(':').map(Number);

  // Compare the hours and minutes
  if (inputHours > currentHours) {
    return true;
  } if (inputHours === currentHours && inputMinutes > currentMinutes) {
    return true;
  }
  return false;
}

export function formatDateString(dateStr) {
  // Create a Date object from the input date string
  const dateObj = new Date(dateStr);

  // Extract the month, day, and year
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();

  // Format the date as MM-DD-YYYY
  return `${month}-${day}-${year}`;
}

export function convertTimeStringToMinutes(timeString) {
  const parts = timeString.toLowerCase().split(' ');
  let totalMinutes = 0;

  for (let i = 0; i < parts.length; i += 2) {
    const value = parseInt(parts[i], 10);
    const unit = parts[i + 1];

    if (Number.isNaN(value)) {
      throw new Error('Invalid time format');
    }

    if (unit.startsWith('hour')) {
      totalMinutes += value * 60;
    } else if (unit.startsWith('min')) {
      totalMinutes += value;
    } else {
      throw new Error('Invalid time unit');
    }
  }

  return totalMinutes;
}

export function getGravatarUrl(username, email, size = 80) {
  // Generate hash using md5 package
  const hash = md5(email?.trim()?.toLowerCase() || 'default@email.com');

  // Encode the username for the fallback URL
  const encodedUsername = encodeURIComponent(username);

  // Construct the Gravatar URL with UI Avatars fallback
  const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=${size}&d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/${encodedUsername}/${size}/random`;

  return gravatarUrl;
}
