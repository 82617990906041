/* eslint-disable no-unused-vars */
function BriefTabContents({
  tabs, activeTab, transcript, meetingDetails, highlight, summary,
}) {
  return (
    <div className="px-4 relative">
      {
        tabs[activeTab].name === 'Summary' && (
          <div>
            {meetingDetails?.summary ? <div dangerouslySetInnerHTML={{ __html: summary }} /> : 'Summary is currently unavailable!'}
          </div>
        )
      }
      {
        tabs[activeTab].name === 'Highlights' && (
          <div>
            {highlight ? <div className="leading-[2rem]" dangerouslySetInnerHTML={{ __html: highlight }} /> : 'Highlight is currently unavailable!'}
          </div>
        )
      }

      {
        tabs[activeTab].name === 'Transcript' && (<div dangerouslySetInnerHTML={{ __html: transcript }} />
        )
      }
    </div>
  );
}

export default BriefTabContents;
