/* eslint-disable object-curly-newline */
/* eslint-disable no-restricted-globals */
import { useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import setAuthToken from '../../actions/setAuthToken';
import Meeting from '../../features/Meeting';
import DashboardIndex from './pages';
import BackOfficePage from './pages/back-office';
import URLEmbed from './pages/back-office/components/url-embed';
import FormBuilder from './pages/back-office/form-builder';
import Scheduling from './pages/back-office/scheduling';
import BillingPage from './pages/billing';
import BriefPage from './pages/brief';
import ContactPage from './pages/contact';
import Meetings from './pages/meetings';
import MyBriefs from './pages/my-briefs';
import ProjectSpace from './pages/project-space';
import ProjectSpaceDetails from './pages/project-space-details';
import SettingsPage from './pages/settings';
import PricingPlan from './pages/plans';

function UserIndexPage() {
  const [user, setUser] = useGlobal('user');
  const setToken = useGlobal('token')[1];
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken(null);
    setUser(null);
    setAuthToken('');
    // localStorage.setItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  useEffect(() => {
    if (user?.isGuest && !location.pathname.includes('/meeting')) {
      handleLogout();
      navigate('/');
    }
  }, [user]);

  return (
    <Routes>
      <Route path="/" element={<DashboardIndex />} />
      <Route path="/briefs" element={<MyBriefs />} />
      <Route path="/briefs/:id" element={<BriefPage />} />
      <Route path="/meeting/:id" element={<Div100vh><Meeting /></Div100vh>} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/availability/*" element={<BackOfficePage />}>
        <Route index element={<Navigate to="/availability/scheduling" replace />} />
        <Route path="scheduling" element={<Scheduling />} />
        <Route path="form-builder" element={<FormBuilder />} />
        <Route path="design" element={<p>Design Coming Soon!</p>} />
        <Route path="url-embed" element={<URLEmbed />} />
      </Route>
      {/* <Route path="/resources" element={<ResourcesPage />} /> */}
      <Route path="/billing" element={<BillingPage />} />
      <Route path="/plans" element={<PricingPlan />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/transcript/:id" element={<BriefPage />} />
      <Route path="/highlights/:id" element={<BriefPage />} />
      <Route path="/project-space" element={<ProjectSpace />} />
      <Route path="/project-space/:id" element={<ProjectSpaceDetails />} />
      <Route path="/meetings" element={<Meetings />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default UserIndexPage;
