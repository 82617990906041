/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import cancelMeeting from '../../../actions/cancelMeeting';
import {
  AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from '../../../components/ui/alert-dialog';
import {
  Dialog, DialogContent,
} from '../../../components/ui/dialog';
import Config from '../../../config';
import { convertTo12Hour } from '../../../lib/utils';
import Reschedule from './reschedule';

function formatTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
  }
  return `${minutes} min${minutes > 1 ? 's' : ''}`;
}

function MeetingScheduleSuccessModal({
  url,
  open,
  setOpen,
  date,
  time,
  timeZone,
  bookingId,
  scheduleId,
  children,
  slot = 15,
  email,
  userName,
}) {
  // const [open, setOpen] = useState(true);
  const [data, setData] = useState(null);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (scheduleId && bookingId) {
      const config = {
        method: 'get',
        url: `${Config.url || ''}/api/v1/public/get_booking/${scheduleId}/${bookingId}`,
      };
      axios.request(config)
        .then(({ data }) => {
          setData(data.data);
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    }
  }, [scheduleId, bookingId]);

  const handleCancelMeeting = async () => {
    const requestData = {
      bookingId,
      scheduleId,
      cancelledBy: email,
    };
    try {
      const { data } = await cancelMeeting(requestData);
      setOpen(false);
      toast.success('Meeting cancelled successfully', {
        position: 'top-center',
      });
      navigate('/dashboard');
    } catch (err) {
      console.log(err.response);
      toast.error(err.response.data.error, {
        position: 'top-center',
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="bg-[#EAECF0] max-w-[641px]">
        <div className="flex flex-col items-center justify-center gap-3 ">
          <img src="/image/icons/check-success.svg" alt="" />
          <div className="w-full text-center px-6 space-y-2.5">
            <h2 className=" m-0 text-lg font-semibold">
              This meeting is scheduled
            </h2>
            <p className="m-0 text-sm">We sent an email with a calendar invitation with the details to everyone.</p>
          </div>
          <div className="bg-white p-3 py-4 rounded-2xl space-y-6">
            <h4 className="text-gray-900 text-lg font-semibold leading-7">Meeting Details</h4>
            <div className="grid grid-cols-2 gap-x-6">
              <div className="space-y-6">
                <div className="space-y-[10px]">
                  <p className="text-slate-600 text-sm font-semibold leading-tight m-0">What</p>
                  <p className="text-slate-600 text-sm font-normal leading-tight m-0 capitalize">
                    {formatTime(slot)}
                    {' '}
                    Meeting
                  </p>
                </div>
                <div className="space-y-[10px]">
                  <p className="text-slate-600 text-sm font-semibold leading-tight m-0">When</p>
                  <p className="text-slate-600 text-sm font-normal leading-tight m-0">
                    {date}
                    {' - '}
                    {convertTo12Hour(time)}
                    {' '}
                    (
                    {timeZone}
                    )
                  </p>
                </div>
              </div>
              <div className="space-y-6">
                <div className="space-y-[10px]">
                  <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Who</p>
                  <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0">
                    {data?.schedule?.user?.firstName}
                    {' '}
                    {data?.schedule?.user?.lastName}
                    <button className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                      Host
                    </button>
                  </p>
                  <p className="text-slate-600 text-sm font-normal leading-tight">{data?.schedule?.user?.email}</p>
                </div>
                {
                  data?.booking?.guests.map((item, i) => (
                    <div className="space-y-[10px]" key={i}>
                      <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0">
                        {item?.name}
                        {' '}
                        <button className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                          Guest
                        </button>
                      </p>
                      <p className="text-slate-600 text-sm font-normal leading-tight">{item?.email}</p>
                    </div>
                  ))
                }

              </div>
              <div />
            </div>
            <div className="space-y-[12px]">
              <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Where</p>
              <Link to={url} className="text-slate-600 text-sm font-semibold font-['Inter'] leading-tight">Saybriefly Video</Link>
            </div>
            <div className="items-center gap-1 flex justify-center text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
              Need to make a change?
              <Reschedule userName={userName} bookingId={bookingId} scheduleId={scheduleId}>
                <button className="underline">Reschedule</button>
              </Reschedule>
              or
              <button
                className="underline"
                onClick={() => {
                  setShowCancelAlert(true);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          {children}
        </div>
        {
          showCancelAlert && (
            <AlertDialog open={showCancelAlert} onOpenChange={setShowCancelAlert}>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Confirm Action</AlertDialogTitle>
                  <AlertDialogDescription>
                    Are you sure you want to cancel this meeting? Once canceled, this action cannot be undone. Press
                    {' '}
                    <strong>Continue</strong>
                    {' '}
                    to confirm.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleCancelMeeting}>Continue</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )
        }
      </DialogContent>
    </Dialog>
  );
}

export default MeetingScheduleSuccessModal;
