/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
export const PLANS = [
    {
        name: 'Free Plan',
        id: 'free',
        price: '$0',
        priceNumber: 0,
        description: 'Perfect for getting started.',
        features: ['30 minutes free'],
        planId: '12345', // Paddle Plan ID for Free Plan
    },
    {
        name: 'Freelancer',
        id: 'freelancers',
        price: '$25/monthly',
        priceNumber: 25,
        description: 'Ideal for freelancers or solopreneurs.',
        features: ['10 hours', 'per seat', 'Standard support'],
        planId: 'pri_01j720d90f5gz4ndydefa166p9', // Paddle Plan ID for Freelancer Plan
    },
    {
        name: 'Small Team',
        id: 'small_teams',
        price: '$45/monthly',
        priceNumber: 45,
        description: 'Best for small teams.',
        features: ['30 hours', 'per seat', 'Priority support'],
        planId: 'pri_01j98rezjye752z6nyatgtagrr', // Paddle Plan ID for Small Team Plan
    },
    {
        name: 'Enterprise',
        id: 'enterprise',
        price: 'Contact us',
        priceNumber: 0,
        description: 'For large organizations.',
        features: ['Unlimited hours', 'Unlimited seats', '24/7 support'],
        contact_link: `${window.location.origin}/contact`,
    },
    {
        name: 'Pay as you go',
        id: 'pay_as_you_go',
        price: '$2.99/hourly',
        priceNumber: 2.99,
        description: 'For large organizations.',
        features: ['Unlimited hours', 'Unlimited seats', '24/7 support'],
        planId: 'pri_01j9edpc9k0ztj000wpy7t7vhf', // Paddle Plan ID for Pay as you go Plan
    },
];
