import axios from 'axios';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import setAuthToken from '../../../actions/setAuthToken';
import config from '../../../config';
import SideNavigation from './side-navigation';

function Sidebar({ showSideBar }) {
  const setToken = useGlobal('token')[1];
  const [user, setUser] = useGlobal('user');
  const token = useGlobal('token')[0];

  const handleLogout = () => {
    setToken(null);
    setUser(null);
    setAuthToken('');
    // localStorage.setItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  async function getUser() {
    axios
      .get(`${config.url || ''}/api/v1/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setUser(res.data.data);
        }
      })
      .catch(async (err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className={`bg-white transition-all h-screen py-8 border border-gray-300 space-y-6 flex flex-col overflow-auto scroll-none md:w-[250px] xl:w-[300px] md:relative absolute top-0 left-0 z-50 ${showSideBar ? 'w-[250px] px-6' : 'w-0 p-0 md:px-6'}`}>
      <Link to="/">
        {/* <Logo /> */}
        <img src="/logo.svg" className="" width="186px" alt="" />
      </Link>

      <div className="flex-grow min-h-[200px] overflow-y-scroll scroll-none">
        <SideNavigation showSideBar={showSideBar} />
      </div>

      {/* My Workspace Details */}

      <div className="text-slate-600 text-sm font-normal leading-tight m-0 border border-gray-200 p-3 rounded-lg">
        <h2 className="text-gray-900 text-lg font-semibold leading-7 mb-4">{user?.workspace?.name}</h2>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight ">
          Available Seats:
          {user?.workspace?.seats}
        </div>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight ">
          Hours Used (Monthly):
          {user?.workspace?.plan !== 'pay_as_you_go'
            ? parseInt(user?.workspace?.hoursUsed, 10)
            : parseInt(user?.workspace?.payAsYouGoHoursUsedMonth, 10)}
        </div>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight  capitalize">
          Current Plan:
          {user?.workspace?.plan?.replace(/_/g, ' ')}
        </div>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight ">
          Last Charged:
          {new Date(user?.workspace?.billingCycleStart).toLocaleDateString()}
        </div>
        <div className="text-slate-600 mt-2 text-xs font-normal leading-tight ">
          Next Billing Date:
          {new Date(Date.parse(user?.workspace?.billingCycleEnd) + 1000 * 60 * 60 * 24 * 30).toLocaleDateString()}
        </div>
      </div>
      <div>
        <hr className="mb-2" />
        {/* <LogoutBtn /> */}
        <button
          onClick={handleLogout}
          className="w-full rounded-md justify-center items-center gap-3 flex bg-gray-100 py-3"
        >
          <span className="text-base font-semibold leading-normal">Logout</span>
          <img src="/image/icons/log-out.svg" width={16} height={16} alt="" />
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
