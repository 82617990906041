/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';

const tabs = [
  {
    name: 'Summary',
    icon: (
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="file-check-02">
          <path
            id="Icon"
            d="M17.5375 11.176V6.17779C17.5375 4.70449 17.5375 3.96784 17.2507 3.40511C16.9985 2.91012 16.5961 2.50768 16.1011 2.25547C15.5384 1.96875 14.8017 1.96875 13.3284 1.96875H7.71637C6.24306 1.96875 5.50641 1.96875 4.94368 2.25547C4.4487 2.50768 4.04626 2.91012 3.79405 3.40511C3.50732 3.96784 3.50732 4.70449 3.50732 6.17779V15.2974C3.50732 16.7707 3.50732 17.5073 3.79405 18.0701C4.04626 18.5651 4.4487 18.9675 4.94368 19.2197C5.50641 19.5064 6.24306 19.5064 7.71637 19.5064H10.5224M12.2762 9.8607H7.01486M8.76863 13.3682H7.01486M14.0299 6.35317H7.01486M12.7146 16.8758L14.4684 18.6295L18.4143 14.6836"
            stroke="currentColor"
            strokeWidth="1.75377"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ),
  },
  {
    name: 'Highlights',
    icon: (
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="dotpoints-01">
          <path
            id="Icon"
            d="M18.4602 10.7378L7.93757 10.7378M18.4602 5.47649L7.93757 5.47649M18.4602 15.9991L7.93757 15.9991M4.43004 10.7378C4.43004 11.2221 4.03744 11.6147 3.55315 11.6147C3.06886 11.6147 2.67627 11.2221 2.67627 10.7378C2.67627 10.2535 3.06886 9.86091 3.55315 9.86091C4.03744 9.86091 4.43004 10.2535 4.43004 10.7378ZM4.43004 5.47649C4.43004 5.96078 4.03744 6.35338 3.55315 6.35338C3.06886 6.35338 2.67627 5.96078 2.67627 5.47649C2.67627 4.9922 3.06886 4.59961 3.55315 4.59961C4.03744 4.59961 4.43004 4.9922 4.43004 5.47649ZM4.43004 15.9991C4.43004 16.4834 4.03744 16.876 3.55315 16.876C3.06886 16.876 2.67627 16.4834 2.67627 15.9991C2.67627 15.5148 3.06886 15.1222 3.55315 15.1222C4.03744 15.1222 4.43004 15.5148 4.43004 15.9991Z"
            stroke="currentColor"
            strokeWidth="1.75377"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ),
  },
];
function MeetingHistoryDetails({ meetingData, getData }) {
  const [activeTab, setActiveTab] = useState(0);
  const io = useSelector((state) => state.io.io);

  const highlightData = meetingData?.highlight?.highlights
    ?.map((highlight) => highlight.replace(/\n/g, '<br/>'))
    .join('<br/><br/>');
  const summeryData = meetingData?.summary?.html.replace(/\n/g, '<br/>');

  useEffect(() => {
    if (io) {
      io.on('generated_brief', (data) => {
        if (data.meetingID === meetingData?._id) {
          if (getData) {
            getData();
          }
        }
      });

      io.on('transcript_not_found', (data) => {
        if (data.meetingID === meetingData?._id) {
          toast.error('Transcript not found', {
            position: 'top-center',
            autoClose: 5000,
          });
        }
      });
    }
  }, [io]);

  return (
    <div className="px-[30px] pt-4 pb-[30px] bg-white rounded-2xl border border-[#d0d5dd] space-y-5 overflow-y-scroll scroll-none flex flex-col h-full">
      <div className="flex justify-between">
        <div className="p-1.5 bg-[#f0f0f0] rounded-full flex items-center gap-1">
          {tabs.map((item, index) => (
            <button
              key={item.name}
              onClick={() => setActiveTab(index)}
              className={`px-3 py-2 flex items-center gap-2 transition-all duration-300 ease-in-out rounded-full ${
                index === activeTab ? 'bg-white shadow-sm' : 'hover:bg-gray-200'
              }`}
            >
              <div
                className={`transition-colors duration-300 ${
                  index === activeTab ? 'text-[#323333]' : 'text-[#667085]'
                }`}
              >
                {item.icon}
              </div>
              <div
                className={`text-sm font-semibold leading-tight transition-colors duration-300 ${
                  index === activeTab ? 'text-[#323333]' : 'text-[#667085]'
                }`}
              >
                {item.name}
              </div>
            </button>
          ))}
        </div>
        <Link
          to={`/briefs/${meetingData?.meeting._id}`}
          className="h-9 px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] text-[#344054] hover:text-[#344054] text-sm font-semibold font-['Inter'] leading-tight"
        >
          View Details
        </Link>
      </div>
      <div className="flex-1 overflow-y-scroll scroll-none overflow-x-visible relative">
        <div className="px-4 relative">
          {tabs[activeTab].name === 'Summary' && (
            <div className="space-y-2">
              {meetingData?.summary ? (
                <div dangerouslySetInnerHTML={{ __html: summeryData }} />
              ) : !meetingData?.highlight &&
                (meetingData?.status === 'ended' || meetingData?.status === 'ended_by_host') ? (
                'Generating Summary... please wait for a while!'
              ) : (
                'Summary is currently unavailable!'
              )}
            </div>
          )}
          {tabs[activeTab].name === 'Highlights' && (
            <div className="space-y-2">
              {meetingData?.highlight ? (
                <div className="leading-[2rem]" dangerouslySetInnerHTML={{ __html: highlightData }} />
              ) : !meetingData?.highlight &&
                (meetingData?.status === 'ended' || meetingData?.status === 'ended_by_host') ? (
                'Generating Highlights... please wait for a while!'
              ) : (
                'Highlights are currently unavailable!'
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MeetingHistoryDetails;
