import md5 from 'md5';
import { useGlobal } from 'reactn';
import Config from '../../../config';
import CreateMeetingButton from './create-meeting-button';
import NotificationButton from './notification-button';

function Topbar({ showSideBar, setShowSideBar }) {
  const user = useGlobal('user')[0];
  const hash = md5(user?.email?.trim()?.toLowerCase());
  const userName = `${user?.firstName} ${user?.lastName}`;
  const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=80&d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/${encodeURIComponent(userName)}/80/random`;
  return (
    <div className="py-[10px] px-8 bg-white border-b border-gray-200 justify-between items-center gap-[15px] flex">
      <button onClick={() => setShowSideBar((prev) => !prev)} className={`relative transition-all duration-300 ease-in-out md:hidden bg-white rounded-full z-[49] p-1 flex items-center justify-center ${showSideBar ? 'border pl-4 pr-1.5 left-[200px]' : 'left-0'}`}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-6 transition-all duration-300 ease-in-out ${showSideBar ? 'hidden' : ''}`}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-6 transition-all duration-300 ease-in-out ${showSideBar ? '' : 'hidden'}`}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
      <div className="flex items-center gap-[15px] ml-auto">
        <CreateMeetingButton className="px-3 py-2 text-sm font-inter" />
        <NotificationButton />
        <button className="justify-start items-center gap-2.5 inline-flex">
          <div className="w-8 h-8 rounded-full justify-center items-center flex overflow-hidden">
            <img src={user?.picture ? `${Config?.url}${user?.picture.location.slice(1)}` : gravatarUrl} alt="Avatar" className="w-8 h-8 relative rounded-full border border-black border-opacity-10 object-cover object-center" />
          </div>
          <div className="flex-col justify-start items-start inline-flex">
            <div className="text-slate-700 text-xs font-medium leading-[18px]">
              {user?.firstName}
              {' '}
              {user?.lastName}
            </div>
          </div>
        </button>
      </div>
    </div>

  );
}

export default Topbar;
