/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../../../config';
import MeetingStats from '../components/meeting-stats';
import ProjectSpaceIntro from '../components/project-space-intro';
import UpcomingMeetings from '../components/upcoming-meetings';
import WelcomeHeading from '../components/welcome-heading';
import Layout from '../layout';

function DashboardIndex() {
  const [meetingStats, setMeetingStats] = useState(null);

  useEffect(() => {
    axios.get(`${Config.url || ''}/api/v1/user/dashboard-meeting-stats`)
      .then((data) => {
        setMeetingStats(data?.data?.stats);
      }).catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Layout>
      <div className="space-y-6 h-full flex flex-col">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-6">
          <div className="flex flex-col gap-8">
            <WelcomeHeading />
            <div className="space-y-6">
              {/* <MeetingHistory /> */}
              <MeetingStats data={meetingStats} />
              <ProjectSpaceIntro />
            </div>
          </div>
          <UpcomingMeetings />
        </div>
      </div>
    </Layout>
  );
}

export default DashboardIndex;
