import { useEffect, useRef } from 'react';
import Config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';
import './Interface.sass';

function Interface({
  audio, video, peer, isMaximized, isScreen,
}) {
  const audioRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (!audio) return;
    if (audio) audioRef.current.srcObject = audio;
  }, [audio]);

  useEffect(() => {
    if (!video) return;
    if (video) videoRef.current.srcObject = video;
  }, [video]);

  return (
    <div className={`interface uk-flex uk-flex-middle uk-flex-center uk-flex-column h-full ${video ? '' : 'max-w-[385px] max-h-[385px]'} mx-auto`}>
      {audio && (
        <audio
          ref={audioRef}
          onLoadedMetadata={() => audioRef.current.play()}
          className="remote-audio"
          controls={false}
          hidden
          data-user={peer}
        />
      )}
      {video && (
        <div className="size-full bg-red-200 bg-opacity-50 flex-grow bg-center bg-cover bg-no-repeat rounded-xl flex flex-col justify-end relative overflow-hidden">
          <video
            ref={videoRef}
            onLoadedMetadata={() => videoRef.current.play()}
            className="remote-video size-full bg-red-200 bg-opacity-50"
            playsInline
            controls={false}
            hidden={false}
            data-user={peer}
            style={{ objectFit: !isMaximized || isScreen ? 'contain' : 'cover' }}
          />
          <button
            className="absolute bottom-4 right-4 bg-[#173300] hover:bg-[#173310] text-[#ffed74] font-bold py-2 px-4 rounded"
            onClick={() => videoRef.current.requestFullscreen()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
            </svg>
          </button>
        </div>
      )}
      {/* {!video && (
        <div className="remote-peer">
          <div className="name">
            {peer?.firstName}
            {' '}
            {peer?.lastName}
          </div>
          <Picture user={peer} />
          <div className="status">{!video && !audio ? 'Spectator' : 'Audio Only'}</div>
        </div>
      )} */}
      {!video && (
        <div className="size-full bg-red-200 bg-opacity-50  flex-grow bg-center bg-cover bg-no-repeat rounded-xl flex flex-col justify-end relative overflow-hidden">
          {
            peer?.picture ? <img src={`${Config.url || ''}/${peer.picture.location}`} alt="Picture" className="h-full w-full" /> : <img src={getGravatarUrl(`${peer?.firstName} ${peer?.lastName}`, peer?.email, 300)} alt="Picture" className="h-full w-full" />
          }
          <div className="w-full h-full absolute top-0 left-0 p-4 flex items-end">
            <div className="p-2 px-4 bg-white rounded-lg justify-start items-start gap-1.5 inline-flex">
              {/* <div className="w-6 h-6 relative" /> */}
              {
                audio && (
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="microphone-02">
                      <path id="Icon" d="M20 12V13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13V12M12 17C9.79086 17 8 15.2091 8 13V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V13C16 15.2091 14.2091 17 12 17Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </svg>
                )
              }

              <div className="text-gray-900 text-base font-semibold leading-normal">
                {peer?.firstName}
                {' '}
                {peer?.lastName}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Interface;
