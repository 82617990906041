/* eslint-disable import/no-unresolved */
/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/function-component-definition */

import axios from 'axios';
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Cross } from 'lucide-react';
import { useGlobal } from 'reactn';
// eslint-disable-next-line import/no-unresolved
import { initializePaddle } from '@paddle/paddle-js';
import { useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import config from '../../../config';
import { PLANS } from '../../../constants/Subscription';
import Layout from '../layout';

const PricingPlan = () => {
  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState();
  const [user] = useGlobal('user');
  const token = useGlobal('token')[0];
  const [selectedSeats, setSelectedSeats] = useState(1);
  const [changePlanWithSeats, setChangePlanWithSeats] = useState(1);
  const location = useLocation();
  const trxId = location?.search?.split('_ptxn=')?.[1];
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();

  // Handle checkout with Paddle
  const handleCheckout = (priceId, quantity) => {
    if (!user?.workspace?._id || !user?._id) {
      window.alert('Reload the page and try again!');
      window.location.reload();
      return;
    }
    setLoading(true);
    paddle?.Checkout.open({
      items: [{ priceId, quantity }],
      customData: {
        workspace_id: user?.workspace?._id,
        user_id: user?._id,
      },
      customer: user?.workspace?.paddleCustomerId
        ? {
            id: user?.workspace?.paddleCustomerId,
          }
        : null,
      settings: {
        successUrl: trxId
          ? `${window.location.origin}/plans/?message=update_billing_success`
          : `${window.location.origin}/plans/?message=success`,
      },
    });
  };

  const handleSeatsChange = (priceId) => {
    setLoading(true);
    axios
      .post(
        `${config.url || ''}/api/v1/user/update-seats-subscription`,
        {
          seats: selectedSeats,
          priceId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/plans/?message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.error?.type === 'request_error') {
          toast.success('Please update billing details and try again!', {
            position: 'top-center',
          });
          // wait here for 3 seconds
          await new Promise((resolve) => {
            setTimeout(resolve, 3000);
          });
          const trxId = err.response?.data?.error?.trx_id;
          if (trxId) {
            window.location.href = `${window.location.origin}/plans/?_ptxn=${trxId}`;
          }
        } else {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        }
      });
  };

  const handleChangePlan = (priceId) => {
    if (user?.workspace?.plan === 'pay_as_you_go') {
      const answer = window.confirm('You will be charged as per usage before changing plan. Are you okay?');
      if (!answer) return;
    }

    setLoading(true);
    axios
      .post(
        `${config.url || ''}/api/v1/user/change-subscription`,
        {
          seats: changePlanWithSeats,
          priceId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/plans/?message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.error?.type === 'request_error') {
          toast.success('Please update billing details and try again!', {
            position: 'top-center',
          });
          // wait here for 3 seconds
          await new Promise((resolve) => {
            setTimeout(resolve, 3000);
          });
          const trxId = err.response?.data?.error?.trx_id;
          if (trxId) {
            window.location.href = `${window.location.origin}/plans/?_ptxn=${trxId}`;
          }
        } else {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        }
      });
  };

  const handlePayAsYouGoPayment = () => {
    setLoading(true);
    axios
      .post(`${config.url || ''}/api/v1/user/pay-as-you-go-payment`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/plans/?message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.error?.type === 'request_error') {
          toast.success('Please update billing details and try again!', {
            position: 'top-center',
          });
          // wait here for 3 seconds
          await new Promise((resolve) => {
            setTimeout(resolve, 3000);
          });
          const trxId = err.response?.data?.error?.trx_id;
          if (trxId) {
            window.location.href = `${window.location.origin}/plans/?_ptxn=${trxId}`;
          }
        } else {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        }
      });
  };

  const handleSwitchToFreePlan = () => {
    if (user?.workspace?.plan === 'pay_as_you_go') {
      const answer = window.confirm('You will be charged as per usage before changing plan. Are you okay?');
      if (!answer) return;
    }

    setLoading(true);
    axios
      .post(`${config.url || ''}/api/v1/user/switch-to-free-plan`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/plans/?message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.message) {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({ environment: 'sandbox', token: 'test_8bb35b35fe5e76274549501c179' }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const message = location?.search?.split('?message=')?.[1];
  useEffect(() => {
    if (!user?.workspace?._id || !user?._id) {
      // window.location.reload();
    } else {
      setChangePlanWithSeats(user?.workspace?.seats);
    }
  }, [user]);

  useEffect(() => {
    if (message === 'success' || message === 'update_billing_success') {
      setLoading(true);
      setTimeout(() => {
        window.location.href = `${window.location.origin}/plans`;
      }, 3000);
    }
  }, [message]);

  return (
    <Layout>
      <div className="container mx-auto py-12 px-4">
        {
          // successful message
          trxId ? (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Success!</strong>
              <span className="block sm:inline">Please update billing details and try again!</span>
              <button
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
                onClick={() => {
                  window.location.href = `${window.location.origin}/plans`;
                }}
              >
                <Cross className="h-6 w-6 text-red-500" />
              </button>
            </div>
          ) : message === 'update_billing_success' ? (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Success!</strong>
              <span className="block sm:inline">
                Billing details updated successfully! Update your plan now. please wait 3s.
              </span>
              <button
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
                onClick={() => {
                  window.location.href = `${window.location.origin}/plans`;
                }}
              >
                <Cross className="h-6 w-6 text-red-500" />
              </button>
            </div>
          ) : message === 'success' ? (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Success!</strong>
              <span className="block sm:inline">Plan updated successfully!. please wait 3s.</span>
              <button
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
                onClick={() => {
                  window.location.href = `${window.location.origin}/plans`;
                }}
              >
                <Cross className="h-6 w-6 text-red-500" />
              </button>
            </div>
          ) : null
        }
        {user?.workspace?.plan === 'pay_as_you_go' && user?.workspace?.payAsYouGoPaymentError ? (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline">
              Plan suspended. Payment failed. Reason: {user?.workspace?.suspensionReason}
            </span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => {
                handlePayAsYouGoPayment();
              }}
            >
              Pay now
            </button>
          </div>
        ) : null}
        <h2 className="text-4xl font-bold text-center mb-6">Pricing Plans</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {PLANS.map((plan) => (
            <div key={plan.name} className="border border-gray-300 rounded-lg p-6 shadow-md">
              <h3 className="text-2xl font-semibold mb-2">{plan.name}</h3>
              <p className="text-3xl font-bold mb-4">{plan.price}</p>
              <p className="text-gray-600 mb-4">{plan.description}</p>
              <ul className="mb-6">
                {plan.features.map((feature, index) => (
                  <li key={index} className="text-gray-800 mb-2">
                    {feature}
                  </li>
                ))}
              </ul>
              {user?.workspace?.plan === plan?.id ? (
                <>
                  <p className="text-gray-800 mb-2">
                    Status: <span className="text-green-500">Active</span>
                  </p>
                  {!['free', 'pay_as_you_go'].includes(user?.workspace?.plan) && (
                    <>
                      <input
                        type="number"
                        value={selectedSeats}
                        onChange={(e) => setSelectedSeats(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 mb-4"
                      />
                      <button
                        disabled={loading}
                        onClick={() => handleSeatsChange(plan.planId, 1, true)}
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                      >
                        {loading ? 'Updating...' : 'Add More Seats'}
                      </button>
                    </>
                  )}
                </>
              ) : plan?.contact_link ? (
                <a
                  href={plan.contact_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                >
                  Contact Us
                </a>
              ) : plan?.id !== 'free' ? (
                <>
                  {user?.workspace?.paddleSubscriptionId && plan?.id !== 'pay_as_you_go' ? (
                    <input
                      type="number"
                      value={changePlanWithSeats}
                      onChange={(e) => setChangePlanWithSeats(e.target.value)}
                      className="border border-gray-300 rounded-lg p-2 mb-4"
                    />
                  ) : null}
                  <button
                    disabled={loading}
                    onClick={() => {
                      if (user?.workspace?.paddleSubscriptionId) {
                        handleChangePlan(plan.planId, user?.workspace?.seats || 0);
                      } else {
                        handleCheckout(plan.planId, 0);
                      }
                    }}
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                  >
                    {loading ? 'Updating...' : 'Upgrade'}
                  </button>
                </>
              ) : (
                <button
                  disabled={loading}
                  onClick={() => handleSwitchToFreePlan()}
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                >
                  {loading ? 'Updating...' : 'Switch To Free'}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default PricingPlan;
