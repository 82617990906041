import { Avatar, AvatarFallback, AvatarImage } from './avatar';

function AvatarGroup({ avatars, max = 3, borderColor }) {
  const displayAvatars = avatars.slice(0, max);
  const remaining = Math.max(avatars.length - max, 0);

  return (
    <div className="flex -space-x-6">
      {displayAvatars.map((avatar, index) => (
        <Avatar size="sm" key={index} className="border-2" style={{ borderColor }}>
          <AvatarImage src={avatar.src} alt={avatar.alt} />
          <AvatarFallback>{avatar.fallback}</AvatarFallback>
        </Avatar>
      ))}
      {remaining > 0 && (
        <Avatar size="sm" className="border-2" style={{ borderColor }}>
          <AvatarFallback>
            +
            {remaining}
          </AvatarFallback>
        </Avatar>
      )}
    </div>
  );
}

export default AvatarGroup;
