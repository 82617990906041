import { useEffect, useState } from 'react';

function MeetingStats({ data }) {
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  useEffect(() => {
    setProgress1(85);
    setProgress2(75);
  }, []);
  return (
    <div className="p-4 bg-[#e7ece4] rounded-xl flex md:flex-row flex-col md:justify-between justify-center items-center gap-6">
      <div>
        <div className="relative size-[190px]">
          <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-[#e7ece4] dark:text-neutral-700" strokeWidth="2" />
            <circle cx="18" cy="18" r="16" fill="none" className="stroke-current transition-all duration-500 ease-in-out text-[#173300] dark:text-[#173300]" strokeWidth="3" strokeDasharray="100" strokeDashoffset={100 - progress1} strokeLinecap="round" />
          </svg>
          <div className="absolute top-0 size-full flex items-center justify-center">
            <svg className="size-[140px] -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-[#e7ece4] dark:text-neutral-700" strokeWidth="2" />
              <circle cx="18" cy="18" r="16" fill="none" className="stroke-current transition-all duration-500 ease-in-out text-[#FCE657] dark:text-[#FCE657]" strokeWidth="4" strokeDasharray="100" strokeDashoffset={100 - progress2} strokeLinecap="round" />
            </svg>
          </div>

          <div className="absolute top-0 left-0 size-full flex items-center justify-center">
            <div className="size-[110px] flex flex-col items-center justify-center">
              <div className="text-center text-[#475467] text-[10.44px] font-medium font-inter leading-none">Total Meeting Time</div>
              <div className="text-center text-black text-[20.88px] font-semibold font-inter leading-7">
                {data?.totalMeetingTime || 0}
                {' '}
                min
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center w-full">
        <div
          className="grid grid-cols-2 gap-8"
        >
          <div className="flex items-start gap-2">
            <div className="pt-1">
              <div className="w-2.5 h-2.5 bg-[#cf7249] rounded-full" />
            </div>
            <div>
              <div className="text-[#475467] text-sm font-medium">Time Saved</div>
              <div className="text-[#101828] text-2xl font-semibold">
                {data?.timeSaved || 0}
                {' '}
                min
              </div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="pt-1">
              <div className="w-2.5 h-2.5 bg-[#8292d5] rounded-full" />
            </div>
            <div>
              <div className="text-[#475467] text-sm font-medium">Avg. Meeting</div>
              <div className="text-[#101828] text-2xl font-semibold">
                {data?.avgMeetingTime || 0}
                {' '}
                min
              </div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="pt-1">
              <div className="w-2.5 h-2.5 bg-[#e08bff] rounded-full" />
            </div>
            <div>
              <div className="text-[#475467] text-sm font-medium">Total Meetings</div>
              <div className="text-[#101828] text-2xl font-semibold">{data?.totalMeetings || 0}</div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="pt-1">
              <div className="w-2.5 h-2.5 bg-[#86dada] rounded-full" />
            </div>
            <div>
              <div className="text-[#475467] text-sm font-medium">Briefs</div>
              <div className="text-[#101828] text-2xl font-semibold">
                {data?.briefs || 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetingStats;
