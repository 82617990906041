// import { useParams } from 'react-router-dom';
// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGlobal } from 'reactn';
import Interface from './Interface';
import './Streams.sass';

function Streams({
  streams, children, isMaximized, isGrid,
}) {
  const consumers = useSelector((state) => state.rtc.consumers);
  const producers = useSelector((state) => state.rtc.producers);
  const peers = useSelector((state) => state.rtc.peers);
  const socketID = useSelector((state) => state.io.id);
  const [mainStream, setMainStream] = useGlobal('mainStream');
  // const user = useGlobal('user')[0];
  // const room = useSelector((state) => state.io.room);
  // const meeting = useGlobal('meeting')[0];
  // const io = useSelector((state) => state.io.io);
  // const params = useParams();
  // const roomID = params?.id;

  const actualConsumers = consumers.filter((c) => c !== socketID);
  let actualPeers = [];
  actualConsumers.forEach((consumerID) => {
    const actualPeer = {
      ...peers[consumerID],
      video: null,
      audio: null,
      screen: null,
    };
    const peerStreams = streams.filter((s) => s.socketID === consumerID);
    peerStreams.forEach((stream) => {
      actualPeer.streams = [...(actualPeer.streams || []), stream];
      if (stream.isVideo) return (actualPeer.video = stream);
      actualPeer.audio = stream;
    });
    const isScreen = (actualPeer.video || actualPeer.screen)
      && producers.filter((p) => p.producerID === actualPeer.video.producerID && p.isScreen).length > 0;
    actualPeers.push({ ...actualPeer, isScreen });
  });

  if (!isGrid && !mainStream && actualPeers.length > 0) {
    setMainStream(actualPeers[actualPeers.length - 1]);
  }

  actualPeers = actualPeers.filter((peer) => peer.approveJoin === true).sort((a) => (((a.audio !== null) || (a.screen !== null) || (a.video !== null)) ? -1 : 1));

  if (!isGrid && mainStream && actualPeers.length > 0) {
    let mainPeer = mainStream;
    actualPeers.forEach((peer) => peer.socketID === mainPeer && (mainPeer = peer));
    return (
      <div className="streams uk-flex uk-flex-middle uk-flex-center uk-flex-column">
        <div className="video-container">
          <div className="video-row">
            <div className="video-wrapper">
              <Interface
                isMaximized={isMaximized}
                video={mainPeer.video}
                audio={mainPeer.audio}
                peer={mainPeer.user}
                isScreen={mainPeer.isScreen}
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    );
  }

  const side = Math.max(Math.ceil(Math.sqrt(actualPeers.length)), 3);
  // const side = Math.ceil(Math.sqrt(actualPeers.length));
  const rows = [];
  const row = [];

  const maxEl = actualPeers[0]?.video !== null ? 4 : 5;

  actualPeers.slice(0, maxEl).forEach((peer, key) => {
    if (row.length === side) {
      // rows.push(
      //   <div className="video-row" key={key}>
      //     {row}
      //   </div>,
      // );
      // row = [];
    }
    if (peer.video) {
      console.log('video', peer.video.getVideoTracks()[0]);
    }
    row.push(
      <div className={`video-wrapper ${(peer.video && key === 0) ? 'col-span-2 row-span-2 min-h-[500px]' : ''}`} key={key}>
        <Interface
          isMaximized={isMaximized}
          video={peer.video}
          audio={peer.audio}
          peer={peer.user}
          isScreen={peer.isScreen}
        />
      </div>,
    );
  });

  if (actualPeers.length > maxEl) {
    row.push(
      <div className="video-wrapper h-full " key={6}>
        <div className="interface rounded-xl overflow-hidden uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-height-1-1 max-w-[385px] mx-auto h-full bg-red-200 bg-opacity-50 ">
          <div className="w-full h-full flex items-center justify-center text-7xl text-white drop-shadow p-6">
            +
            {actualPeers.length - maxEl}
          </div>
        </div>
      </div>,
    );
  }

  if (row.length > 0) {
    rows.push(
      <div className="video-row" key="last">
        {row}
      </div>,
    );
  }

  return (
    <div
      className="streams uk-flex uk-flex-middle uk-flex-center uk-flex-column"
    >
      {actualPeers.length === 0 && <p className="waiting">Waiting for others to join...</p>}
      {actualPeers.length > 0 && <div className="video-container">{rows}</div>}
      {children}
    </div>
  );
}

export default Streams;
