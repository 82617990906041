/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-unresolved */
import AvatarGroup from '../../../components/ui/avatar-group';
import Config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';

export function getHourMinuteDifference(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const diffMilliseconds = Math.abs(date2 - date1);
  const days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  return `${minutes} min.`;
}

function MeetingHistoryItem({
  data, selectedMeeting = null, setSelectedMeeting = () => { }, maxAvatar = 2,
}) {
  const users = typeof data?.users[0] !== 'string' ? data?.users?.map((user) => ({
    src: (user.picture ? `${Config.url || ''}/${user.picture.location}` : getGravatarUrl(`${user.firstName} ${user.lastName}`, user.email)),
    fallback: user.firstName.charAt(0) + user.lastName.charAt(0),
    alt: '',
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
  })) : [getGravatarUrl(`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`, data?.createdBy?.email)];

  const max = typeof data?.users[0] !== 'string' ? maxAvatar : 1;

  return (
    <div onClick={() => setSelectedMeeting(data)} className={`flex py-4 px-6 items-center justify-between gap-2.5 rounded-xl border border-gray-200 bg-white cursor-pointer ${selectedMeeting?._id === data?._id ? 'border-[#ffed74]' : ''}`}>
      <div className="flex items-center gap-3">
        <div className={`${max === 1 ? 'w-[45px]' : 'w-[65px]'}`}>
          <AvatarGroup avatars={users} borderColor="#ffed74" max={max} />
        </div>
        <div className="flex gap-3 items-end">
          {users.slice(0, 2).map((user, index) => (
            <div key={user?.email} className="flex items-end">
              <div className="flex flex-col">
                <p className="text-[#101828] text-sm font-medium leading-tight whitespace-nowrap capitalize" id={`user-name-${user?.email}`}>
                  {user?.name}
                </p>
                <p
                  className="text-[#475467] text-xs font-normal font-['Inter'] leading-[18px] truncate"
                  style={{ maxWidth: document.getElementById(`user-name-${user?._id}`)?.offsetWidth || '100px' }}
                >
                  {user?.email}
                </p>
              </div>
              {index !== users.length - 1 && <span>,</span>}
            </div>
          ))}
          {
            users.length > 2 && (
              <p className="text-[#344054] text-xs font-medium font-['Inter'] leading-[18px]">
                <span>
                  {users.length - 2}
                  {' '}
                  {users.length - 2 === 1 ? 'other' : 'others'}
                </span>
              </p>
            )
          }
        </div>
        {/* <div className="flex flex-col gap-2">
          <h4 className="text-gray-900 text-sm font-semibold leading-tight m-0">
            {`${data?.createdBy?.firstName || 'Unknown'} ${data?.createdBy?.lastName || ''}`}
          </h4>
          <div className="flex gap-2 text-sm text-slate-600">
            <div>
              {type === 'upcoming' ? `${convertTo24Hour(data?.booking?.bookingTime)} - ` : ''}

              {moment(data?.booking?.bookingDate).format('DD/MM/YYYY')}

              {type === 'upcoming' ? (
                ''
              ) : (
                <>
                  {' - '}
                  {getHourMinuteDifference(new Date(), data?.lastLeave || 0)} ago
                </>
              )}
            </div>
          </div>
        </div> */}
      </div>
      <div className="flex flex-col gap-2 font-inter">
        <h4 className="text-[#475467] text-sm font-normal leading-tight">
          {new Date(data?.startedAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}
        </h4>
        <h4 className="text-[#475467] text-sm font-normal leading-tight">{getHourMinuteDifference(data?.lastEnter, data?.lastLeave)}</h4>
      </div>
    </div>
  );
}

export default MeetingHistoryItem;
